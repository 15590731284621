#entryViewTable {
	width: 800px;
	margin: 8px;
	min-width: 0;
}
#textFields {
	width: 65%;
	height: 100%;
	padding-left: 16px;
	border-left: 1px solid lightgray;
}
#info {
	width: 35%;
	height: 45%;
	padding-right: 16px;
	padding-bottom: 16px;
	border-bottom: 1px solid lightgray;
	vertical-align: text-top;
}
#groups {
	height: 40%;
	padding-right: 16px;
}
#buttons {
	border-top: 1px solid lightgray;
	height: 15%;
	padding-right: 16px;
	padding-top: 10px;
}
