body {
	min-width: 1920px;
}

#logo {
	height: 35px;
	width: 99px;
	vertical-align: middle;
	float: left;
	margin-right: 20px;
}
#account {
	margin-left: 16px;
	margin-right: 16px;
	display: flex;
	flex-direction: row;
	justify-content: center;
}
