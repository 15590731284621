#settings {
	position: fixed;
	height: calc(100vh - 48px);
	width: 100%;
	background-color: #455a64;
	top: 48px;
	padding: 16px;
	overflow: hidden;
}
#cardsContainer {
	overflow: auto;
	display: flex;
	justify-content: top;
	flex-direction: column;
	align-items: center;
	height: 100%;
}
.settingsHeader {
	color: white;
	font-size: 108%;
	font-weight: 400;
	margin-bottom: 12px;
	margin-top: 12px;
}
.settingsCard {
	width: 600px;
	text-align: center;
}
