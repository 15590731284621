/*toolbar style*/
#toolbar::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: #78909c;
}

#toolbar::-webkit-scrollbar {
	width: 6px;
	background-color: #78909c;
}

#toolbar::-webkit-scrollbar-thumb {
	background-color: #c1c1c1;
}
#toolbar {
	z-index: 3;
	position: fixed;
	top: 48px;
	width: 250px;
	height: calc(100vh - 48px);
	background-color: #78909c;
	float: left;
	display: inline;
	text-align: center;
	overflow-y: auto;
	overflow-x: hidden;
}
#shadowHolder {
	position: fixed;
	top: 48px;
	left: 250px;
	width: 100%;
	height: 54px;
}
p {
	font-size: 26px;
	font-family: "Roboto";
	padding-top: 15px;
	color: #ffffff;
	border-bottom: 1px solid white;
	margin-left: 30px;
	margin-right: 30px;
}
.form-group {
	padding: 15px;
}
.form-check-label {
	color: white;
	padding-left: 20px;
	font: 16px Arial, sans-serif;
}
*:focus {
	outline: 0 !important;
}
/*table style*/
table {
	border-collapse: collapse;
	min-width: 2100px;
}
#container {
	position: fixed;
	top: 48px;
	left: 250px;
	bottom: 0px;
	right: 0px;
}
.tableHead {
	background-color: #455a64;
	color: white;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 16px;
	font-size: 0.875rem;
	text-align: left;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	border-bottom: 1px solid rgba(224, 224, 224, 1);
	letter-spacing: 0.01071em;
	vertical-align: inherit;
}
.tableHeadCheckbox {
	background-color: #455a64;
	color: white;
	padding: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.tableCellCheckbox {
	padding: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.tableCellCheckboxSelected {
	background-color: #fdf4f4;
	padding: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.tableCellCheckboxInactive {
	background-color: #e73e33;
	color: white;
	padding: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.tableCell {
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 16px;
	font-size: 0.875rem;
	text-align: left;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	border-bottom: 1px solid rgba(224, 224, 224, 1);
	letter-spacing: 0.01071em;
	vertical-align: inherit;
}
.tableCellSelected {
	background-color: #fdf4f4;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 16px;
	font-size: 0.875rem;
	text-align: left;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	border-bottom: 1px solid rgba(224, 224, 224, 1);
	letter-spacing: 0.01071em;
	vertical-align: inherit;
}
.tableCellInactive {
	background-color: #e73e33;
	color: white;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 16px;
	font-size: 0.875rem;
	text-align: left;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	border-bottom: 1px solid rgba(224, 224, 224, 1);
	letter-spacing: 0.01071em;
	vertical-align: inherit;
}
