#dashboardContainer {
	position: fixed;
	top: 56px;
	bottom: 8px;
	left: 8px;
	right: 8px;
	overflow: auto;
}
#grid {
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	min-width: 1200px;
	min-height: 650px;
}
#card1 {
	/*Group Counts*/
	width: calc(33.333% - 16px);
	height: calc(50% - 16px);
	margin: 8px;
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 8px;
}
#card2 {
	/*Provider Locations*/
	top: 50%;
	width: calc(33.333% - 16px);
	height: calc(50% - 16px);
	margin: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	padding: 8px;
}
#card3 {
	/*Inactive Records*/
	left: 33.333%;
	width: calc(16.6665% - 16px);
	height: calc(25% - 16px);
	margin: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
}
#card4 {
	/*Total Records*/
	left: 50%;
	width: calc(16.6665% - 16px);
	height: calc(25% - 16px);
	margin: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
}
#card5 {
	left: 33.333%;
	top: 25%;
	width: calc(33.333% - 16px);
	height: calc(75% - 16px);
	margin: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	padding: 8px;
}
#card6 {
	/*Map*/
	left: 66.666%;
	width: calc(33.333% - 16px);
	height: calc(100% - 16px);
	margin: 8px;
	position: absolute;
}
#chart {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}
