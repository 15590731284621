#background {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: #455a64;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	overflow: hidden;
}
html {
	overflow: hidden;
}
#card {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 350px;
	min-height: 260px;
	transform: translate(-50%, -50%);
	padding-left: 50px;
	padding-right: 50px;
	padding-top: 20px;
	padding-bottom: 20px;
	text-align: center;
}
#login {
	margin-top: 10px;
}
#logo-login {
	height: 53px;
	width: 150px;
}
#crm-tech {
	position: absolute;
	left: 0%;
	top: 100%;
	transform: translate(15%, -150%);
	width: 150px;
}
.circle {
	border-radius: 50%;
	background-color: #ffffff;
}
#circle1 {
	position: absolute;
	left: -10%;
	top: 60%;
	width: 50vw;
	height: 50vw;
}
#circle2 {
	position: absolute;
	left: 80%;
	top: 10%;
	width: 30vw;
	height: 30vw;
}
#circle3 {
	position: absolute;
	left: 50%;
	top: 40%;
	width: 25vw;
	height: 25vw;
}
#circle4 {
	position: absolute;
	left: 48%;
	top: 55%;
	width: 35vw;
	height: 35vw;
}
#circle5 {
	position: absolute;
	left: -30%;
	top: -10%;
	width: 60vw;
	height: 60vw;
}
